/* eslint-disable no-unreachable */
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { OrderDetail, OrderType } from "types";
import { ProgressSpinner } from "primereact/progressspinner";
import { db, useAuth } from "context";

import { DateTime } from "luxon";
import { getProductPath } from "utils/getProductPath";

import { Product } from "./Product";
import { CanvasComputer, Progress } from "./computeCanvas";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { OrderExporting } from "./OrderExporting";

type ErrorType = {
  hasError: boolean;
  message: string;
};

export const OrderDetails = () => {
  const [exporting, setExporting] = useState(false);

  const [progress, setProgress] = useState<any>({});

  Progress.logProgress = (callback) => {
    console.log(callback);
    setProgress(callback);
  };

  const { id } = useParams();
  const { formatMessage } = useAuth();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<OrderType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderStatus, setOrderStatus] = useState<number | undefined>(undefined);
  const [error, setError] = useState<ErrorType>({
    hasError: false,
    message: "",
  });

  const handleUpdateStatus = (orderStatus: number) => {
    const docRef = doc(db, "orders", `${id}`);

    updateDoc(docRef, {
      ...orderDetails,
      status: orderStatus,
    })
      .then((res) => {
        setOrderStatus(orderStatus);
        setTrigger(!trigger);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setIsLoading(true);
    const docRef = doc(db, "orders", `${id}`);
    getDoc(docRef)
      .then((response) => {
        if (response.exists()) {
          setOrderDetails({ id: response.id, ...response.data() } as OrderType);

          if (orderDetails?.status) {
            setOrderStatus(orderDetails.status);
          }
        } else {
          // doc.data() will be undefined in this case
          setError({ hasError: true, message: "No such document!" });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          hasError: true,
          message: `Error in fetching data ${error}`,
        });
        setIsLoading(false);
      });
  }, [id, orderDetails?.status, trigger]);

  if (!orderDetails) {
    return null;
  }

  const handleDownload = async (
    orderDetails: OrderType,
    detail: OrderDetail,
    downloadType: string,
    dpi: number,
    margin: number,
    sideBySide: boolean
  ) => {
    console.log("trying with", dpi, margin);
    try {
      setExporting(true);

      const canvasComputer = new CanvasComputer({
        orderDetails,
        detail,
        downloadType,
        dpi,
        margin,
        sideBySide,
      });

      await canvasComputer.computeImages();

      // canvasComputer.destroy();

      // detail.pages = detail.pages.slice(0, 4);
      // detail.photos = detail.pages
      //   .flatMap((page) =>
      //     page.elements
      //       .filter((element) => element.type === "Image")
      //       .map((element) => element.imageUrl)
      //   )
      //   .filter((src, index, arr) => src && !arr.slice(0, index).includes(src));
      // await computeImages(
      //   orderDetails,
      //   detail,
      //   downloadType,
      //   dpi,
      //   margin,
      //   sideBySide
      // );

      // setExporting(false);
    } catch (e) {
      console.log("got error", e);
      setExporting(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex mt-8">
          <ProgressSpinner />
        </div>
      ) : (
        <Card className="mb-4 p-card-no-shadow p-0">
          <div className="col-12 flex p-2 surface-100 border-round-top">
            <div className="p-2 flex-auto text-center md:text-left">
              <span className="text-600 block">Order Id</span>
              <span className="text-900 font-medium block mt-2">{id}</span>
            </div>
            <Divider
              align="center"
              layout="vertical"
              className="h-full  mx-0 lg:mx-3 surface-border"
            ></Divider>
            <div className="p-2 flex-auto text-center md:text-left">
              <span className="text-600 block">Order Date</span>
              <span className="text-900 font-medium block mt-2">
                {DateTime.fromSeconds(orderDetails.updatedAt.seconds)
                  .setLocale("ro")
                  .toLocaleString(DateTime.DATETIME_FULL)}
              </span>
            </div>
            <Divider
              align="center"
              layout="vertical"
              className="h-full mx-0 lg:mx-3 surface-border"
            ></Divider>
            <div className="p-2 flex-auto text-center md:text-left">
              <span className="text-600 block">Price Details</span>
              <span className="text-900 font-medium block mt-2">
                Order price: {orderDetails.costs.total / 100} RON
              </span>
              <span className="text-900 font-medium block mt-2">
                Delivery: {orderDetails.costs.deliveryFee.value / 100} RON
              </span>
              <span className="text-900 font-medium block mt-2">
                Total:{" "}
                {(orderDetails.costs.total +
                  orderDetails.costs.deliveryFee.value) /
                  100}{" "}
                RON
              </span>
            </div>
          </div>
          {orderDetails?.paymentIntent && (
            <div className="bg-gray-100 p-3 mt-6 flex align-items-baseline">
              Stripe payment intent:
              <p className="pl-3">{orderDetails?.paymentIntent}</p>
              <Button
                className="ml-6"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${orderDetails?.paymentIntent}`
                  )
                }
              >
                {" "}
                Copy text!
              </Button>
            </div>
          )}
          <div className="mt-6 flex flex-row justify-content-around bg-gray-100 p-3 align-items-baseline border-round-md">
            {orderStatus && (
              <div className="flex flex-row align-items-baseline">
                <p className="pr-2">Change order status:</p>
                <Dropdown
                  value={orderStatus}
                  options={[
                    { value: 7, label: "Order Confirmed" },
                    { value: 8, label: "Preparing" },
                    { value: 9, label: "Delivered" },
                  ]}
                  onChange={(e) => handleUpdateStatus(e.value)}
                  placeholder="Select a status"
                />
              </div>
            )}

            <p
              className={`p-2 border-round-md w-20rem text-center uppercase ${
                orderDetails.costs.paymentMethod === "cash"
                  ? "bg-teal-200"
                  : "bg-pink-200"
              }`}
            >
              {orderDetails.costs.paymentMethod === "cash"
                ? "cash at delivery"
                : "card"}
            </p>
            <a
              target="_new"
              href={`https://console.firebase.google.com/u/0/project/blabla-app-69477/storage/blabla-app-69477.appspot.com/files/~2Fphotos~2F${orderDetails.userId}~2F${id}`}
              className="no-underline"
            >
              <Button
                icon="pi pi-draw"
                className="no-underline"
                label="Go to photos storage"
              />
            </a>
          </div>
          <Card className="mb-4 p-card-no-shadow p-0 bg-gray-100 mt-6">
            <p>Delivery address:</p>
            <div className="grid">
              <div className="col">
                <p>Name: {orderDetails.deliveryAddress.recipientName}</p>
                <p>Phone: {orderDetails.deliveryAddress.phoneNumber}</p>
              </div>
              <br />
              <div className="col">
                <p>Country: {orderDetails.deliveryAddress.country}</p>
                <p>County: {orderDetails.deliveryAddress.county}</p>
                <p>City: {orderDetails.deliveryAddress.city}</p>
              </div>
              <br />
              <div className="col">
                <p>Street: {orderDetails.deliveryAddress.streetName}</p>
                <p>Number: {orderDetails.deliveryAddress.number}</p>
              </div>
              <br />
              <div className="col">
                <p>Flat: {orderDetails.deliveryAddress.flat}</p>
                <p>Apartment:{orderDetails.deliveryAddress.apartment}</p>
              </div>
              <br />
              <div className="col">
                <p>Zipcode: {orderDetails.deliveryAddress.zipCode}</p>
              </div>
            </div>
          </Card>

          <div className="col-12">
            {orderDetails.orderDetails.map((details, index) => (
              <Fragment key={index}>
                <Product
                  orderDetails={orderDetails}
                  productPath={getProductPath(orderDetails, index, 0)}
                  name={formatMessage(details.product.name)}
                  details={details}
                  exporting={exporting}
                  handleDownload={handleDownload}
                />
                <Divider className="w-full block surface-border"></Divider>{" "}
              </Fragment>
            ))}
          </div>
        </Card>
      )}
      {exporting && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-white flex p-component align-items-center justify-content-center">
          <div className="self-center text-center flex justify-content-between">
            <img
              src="/animation.gif"
              alt="Animation gif"
              style={{ objectFit: "contain" }}
            />
            <div className="w-30rem">
              <OrderExporting data={progress}></OrderExporting>
              {/* <h1 className="text-4xl text-color font-bold mb-3">Exporting</h1>
              <ProgressBar
                value={((progress?.percentage || 0) * 100).toFixed(2)}
              />
              <div className="mt-1 text-color">{progress?.step}</div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
