import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

import { Card } from "primereact/card";
import { OrderType } from "types";
import { OrdersTable } from "./components";
import { ProgressSpinner } from "primereact/progressspinner";
import { db } from "context";

export const Orders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderType[] | []>([]);

  useEffect(() => {
    setIsLoading(true);
    const colRef = collection(db, "orders");
    getDocs(query(colRef, where("status", ">=", 4)))
      .then((response) => {
        const ordersArray = response.docs.map((doc) => ({
          orderId: doc.id,
          ...doc.data(),
        }));
        ordersArray.sort(
          (order1: any, order2: any) =>
            order2.updatedAt.seconds - order1.updatedAt.seconds
        );
        console.log("Orders", orders);
        setOrders(ordersArray as any[]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(true);
      });
  }, []);

  return (
    <>
      <Card
        title="Orders"
        subTitle={`You have ${orders.length} orders`}
        className="mb-4 p-card-no-shadow"
      />
      {isLoading ? (
        <div className="flex mt-8">
          <ProgressSpinner />
        </div>
      ) : (
        <OrdersTable orders={orders} updateOrderStatus={() => undefined} />
      )}
    </>
  );
};
