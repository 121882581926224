import { PropsWithChildren } from "react";
import { useAuth } from "./AuthHooks";

export interface RoleGuardProps {
  role: string;
}

export const RoleGuard = ({ role, children }: PropsWithChildren<RoleGuardProps>) => {
  const { userAdmin } = useAuth();
  if (!userAdmin?.roles?.includes(role)) {
    return null;
  }

  return <>{children}</>;
};

export const useRoleGuard = (role: string) => {
  const { userAdmin } = useAuth();
  return userAdmin?.roles?.includes(role);
};
