import { Controller, useFormContext } from "react-hook-form";

import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

export const EditProductGroup = ({ data }: any) => {
  const { watch, register } = useFormContext();

  const values = watch();

  //   const entries = Object.entries(values)

  const productVariants = data["product-variants"] || [];

  return (
    <div className="grid">
      <div className="col-8 field">
        <label htmlFor="name-input" className="block text-sm pl-2 ">
          Name
        </label>
        <InputText className="w-full" id="name-input" {...register("name")} />
      </div>
      <div className="col-8 field">
        <label htmlFor="imageUrl-input" className="block text-sm pl-2 ">
          Image URL
        </label>
        <InputText className="w-full" id="imageUrl-input" {...register("imageUrl")} />
      </div>
      {values.imageUrl && (
        <div className="col-5 field">
          <label className="block text-sm pl-2 ">Preview Image URL</label>
          <img src={values.imageUrl} className="w-full" alt="decorative" />
        </div>
      )}
      <div className="col-8 field">
        <label htmlFor="variants-input" className="block text-sm pl-2 ">
          Product Variants
        </label>

        <Controller
          name="productVariants"
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
            <MultiSelect
              id="variants-input"
              display="chip"
              className="w-full"
              optionLabel="name"
              optionValue="id"
              options={productVariants || []}
              onBlur={onBlur}
              value={value?.map((v: any) => v.id)}
              filter
              onChange={(e) => onChange(e.value?.map((id: any) => ({ id })))}
            />
          )}
        />
      </div>
    </div>
  );
};
