import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

import { Card } from "primereact/card";
import { CmsForm } from "./CmsForm";
import { CmsTable } from "./CmsTable";
import { EditProduct } from "./edit-components/EditProduct";
import { EditProductGroup } from "./edit-components/EditProductGroup";
import { EditProductVariants } from "./edit-components/EditProductVariants";
import { EditTranslations } from "./edit-components/EditTranslations";
import { Menu } from "primereact/menu";
import { OrderDetails } from "pages/OrderDetails";
import { Orders } from "pages/Orders";
import { db } from "context";

const GENERAL_COLUMNS = [
  { field: "code", header: "Code" },
  { field: "name", header: "Name" },
  { field: "category", header: "Category" },
  { field: "quantity", header: "Quantity" },
];

export const Cms = () => {
  const [data, setData] = useState<any>({});

  const navigate = useNavigate();

  const PRODUCT_GROUPS_COLUMNS = [
    { field: "name", header: "Name", filter: true, sortable: true },
    {
      field: "productVariants.id",
      header: "Product Variants",
      body: (body: any) =>
        body.productVariants.map((variant: any) => data["product-variants"]?.find((v: any) => v.id === variant.id)?.name)?.join(", "),
      filter: true,
      sortable: true,
    },
    {
      field: "imageUrl",
      header: "Image",
      body: (body: any) => <img src={body.imageUrl} style={{ height: 40 }} />,
      filter: true,
      sortable: true,
    },
  ];

  const PRODUCT_VARIANTS_COLUMNS = [
    { field: "name", header: "Name", filter: true, sortable: true },
    { field: "value", header: "Value", filter: true, sortable: true },

    { field: "attribute", header: "Attribute", filter: true, sortable: true },
    {
      field: "productVariants.id",
      header: "Products",
      body: (body: any) => body.products.map((variant: any) => data["products"]?.find((v: any) => v.id === variant.id)?.name)?.join(", "),
      filter: true,
      sortable: true,
    },
  ];

  const PRODUCTS_COLUMNS = [
    { field: "name", header: "Name", filter: true, sortable: true },
    {
      field: "attributes",
      header: "Attributes",
      body: (body: any) => Object.keys(body.attributes).join(", "),
      filter: true,
      sortable: true,
    },
    {
      field: "imageUrl",
      header: "Image",
      body: (body: any) => <img src={body.imageUrl} style={{ height: 40 }} />,
      filter: true,
      sortable: true,
    },
    { field: "cartFields", header: "Cart Fields", body: (body: any) => body.cartFields.join(", "), filter: true, sortable: true },
    {
      field: "descriptionFields",
      header: "Description Fields",
      body: (body: any) => body.descriptionFields.join(", "),
      filter: true,
      sortable: true,
    },
    {
      field: "description",
      header: "Description",
      body: (body: any) => (body.description.length > 40 ? `${body.description.substr(0, 40)}...` : body.description),
      filter: true,
      sortable: true,
    },

    {
      field: "projectConfig.id",
      header: "Project Config",
      body: (body: any) => data["project-configs"]?.find((v: any) => v.id === body.projectConfig.id)?.name,
      filter: true,
      sortable: true,
    },
  ];

  const PRODUCT_GROUP_INITIAL_STATE = {
    name: "",
    imageUrl: "",
    productVariants: [],
  };

  const PRODUCT_VARIANT_INITIAL_STATE = {
    name: "",
    attribute: "",
    value: "",
    products: [],
  };

  const PRODUCTS_INITIAL_STATE = {
    name: "",
    imageUrl: "",
    description: "",
    descriptionFields: [],
    attributes: { price: undefined },
    cartFields: [],
    attributeIcons: [],
  };

  const registerSnapshots = useCallback((collectionNames: string[], callback: (data: any) => void) => {
    let data = {};
    const unsubscribes = collectionNames.map((collectionName) => {
      const colRef = collection(db, collectionName);
      return onSnapshot(colRef, {
        next: (response) => {
          const ordersArray = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          data = { ...data, [collectionName]: ordersArray };
          callback(data);
        },
        error: () => {},
      });
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  useEffect(() => {
    return registerSnapshots(["product-groups", "product-variants", "products", "project-configs", "translations"], (data) => {
      setData(data);
    });
  }, [registerSnapshots]);

  const items = [
    { label: "General", command: () => navigate("/cms/general") },
    { label: "Translations", command: () => navigate("/cms/translations") },
    { label: "Product Groups", command: () => navigate("/cms/product-groups") },
    { label: "Product Variants", command: () => navigate("/cms/product-variants") },
    { label: "Products", command: () => navigate("/cms/products") },
    { label: "Project Configs", command: () => navigate("/cms/project-configs") },
  ];
  return (
    <div className="grid">
      <div className="col-fixed">
        <Menu model={items} />
      </div>
      <div className="col">
        <Routes>
          <Route
            path="/general"
            element={<CmsTable dataId="general" title="General" cols={GENERAL_COLUMNS} data={data["general"] || []} />}
          />
          <Route
            path="/product-groups"
            element={
              <CmsTable dataId="product-groups" title="Product Groups" cols={PRODUCT_GROUPS_COLUMNS} data={data["product-groups"] || []} />
            }
          />
          <Route
            path="/product-groups/:id"
            element={
              <CmsForm
                title="Product Group"
                data={data["product-groups"] || []}
                collectionName="product-groups"
                dataKey={"name"}
                emptyState={PRODUCT_GROUP_INITIAL_STATE}
              >
                <EditProductGroup data={data} />
              </CmsForm>
            }
          />
          <Route
            path="/product-variants"
            element={
              <CmsTable
                dataId="product-variants"
                title="Product Variants"
                cols={PRODUCT_VARIANTS_COLUMNS}
                data={data["product-variants"] || []}
              />
            }
          />
          <Route
            path="/product-variants/:id"
            element={
              <CmsForm
                title="Product Variants"
                data={data["product-variants"] || []}
                collectionName="product-variants"
                dataKey={"name"}
                emptyState={PRODUCT_VARIANT_INITIAL_STATE}
              >
                <EditProductVariants data={data} />
              </CmsForm>
            }
          />

          <Route
            path="/products"
            element={<CmsTable dataId="products" title="Products" cols={PRODUCTS_COLUMNS} data={data["products"] || []} />}
          />

          <Route
            path="/products/:id"
            element={
              <CmsForm
                title="Products"
                data={data["products"] || []}
                collectionName="products"
                dataKey={"name"}
                emptyState={PRODUCTS_INITIAL_STATE}
              >
                <EditProduct data={data} />
              </CmsForm>
            }
          />

          <Route
            path="/project-configs"
            element={<CmsTable dataId="project-configs" title="Project Configs" cols={[]} data={data["project-configs"] || []} />}
          />
          <Route
            path="/translations"
            element={
              <CmsForm
                title="Translations"
                data={data["translations"] || []}
                collectionName="translations"
                dataKey={"name"}
                isCollection
                emptyState={{}}
              >
                <EditTranslations data={data} />
              </CmsForm>
            }
          />
          <Route path="*" element={<Navigate to="/cms/general" />} />
        </Routes>
      </div>
    </div>
  );
};
