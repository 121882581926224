import { Login } from "pages/Login/Login";
import { PropsWithChildren } from "react";
import { useAuth } from "./AuthHooks";
import { DeletionSteps } from "pages/deletion-steps";

export const AuthGuard = ({ children }: PropsWithChildren<unknown>) => {
  const { user, loading } = useAuth();

  console.log("location", window.location);

  if (window.location.pathname === "/delete-account") {
    return <DeletionSteps />;
  }

  if (!user || loading) {
    return <Login />;
  }
  return <>{children}</>;
};
