import { OrderStatusType, STATUS_CLASSES_MAP, STATUS_MAP } from "./types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FunctionComponent } from "react";
import { OrderType } from "../../../types";
import { useNavigate } from "react-router-dom";

const paginatorLeft = (
  <Button type="button" icon="pi pi-refresh" className="p-button-text" />
);
const paginatorRight = (
  <Button type="button" icon="pi pi-cloud" className="p-button-text" />
);

interface PropTypes {
  orders: OrderType[];
  updateOrderStatus: (value: string, orderId: string) => void;
}

export const OrdersTable: FunctionComponent<PropTypes> = ({
  orders,
  updateOrderStatus,
}) => {
  const navigate = useNavigate();

  const navigateOrderDetails = (rowData: any) => {
    return (
      <Button onClick={() => navigate(`/orders/${rowData.orderId}`)}>
        Details & Export
      </Button>
    );
  };
  return (
    <div className="border-round-xl overflow-hidden flex flex-column flex-grow-1">
      <DataTable
        value={orders}
        responsiveLayout="scroll"
        scrollable
        scrollHeight="flex"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={20}
        rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        className="bg-white"
      >
        <Column field="orderId" header="Order Id" />
        <Column
          field="order"
          header="User Name"
          body={({ deliveryAddress }) => {
            return (
              <p>
                {deliveryAddress?.recipientName
                  ? deliveryAddress.recipientName
                  : "NO_NAME"}
              </p>
            );
          }}
        />
        <Column
          field="status"
          header="Status"
          body={({ status }) => {
            return (
              <span
                className={`customer-badge ${
                  STATUS_CLASSES_MAP[status as keyof typeof STATUS_CLASSES_MAP]
                }`}
              >
                {STATUS_MAP[status as keyof typeof STATUS_MAP]}
              </span>
            );
          }}
        />
        <Column
          field="type"
          header="Payment Method"
          body={(details) => {
            if (details?.costs?.paymentMethod === "cash") {
              return (
                <span className={`customer-badge bg-green-100 text-green-900`}>
                  Cash at Delivery
                </span>
              );
            } else if (details?.costs?.paymentMethod === "card") {
              return (
                <span className={`customer-badge bg-blue-100 text-blue-900`}>
                  Card
                </span>
              );
            } else {
              return (
                <span className={`customer-badge bg-gray-100 text-gray-900`}>
                  Not confirmed
                </span>
              );
            }
          }}
        />
        <Column
          field="createdAt"
          header="Created at"
          body={({ createdAt }) => {
            const date = new Date(createdAt.seconds * 1000);

            return <p>{date.toLocaleDateString("ro-RO")}</p>;
          }}
        />
        <Column
          field="updatedAt"
          header="Updated at"
          body={({ updatedAt }) => {
            const date = new Date(updatedAt.seconds * 1000);

            return <p>{date.toLocaleDateString("ro-RO")}</p>;
          }}
        />
         <Column field="costs.total" header="Price" body={({ costs }) => (costs.total + costs.deliveryFee.value) /100 + " RON"} />
        <Column body={navigateOrderDetails} align="right"></Column>
      </DataTable>
    </div>
  );
};
