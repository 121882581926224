export enum OrderStatusType {
  // upload order detials
  CREATED,

  // upload photos to bucket and merge url with order details
  // PHOTOS_UPLOAD_STARTED,
  PHOTOS_UPLOAD_COMPLETED,
  // PHOTOS_UPLOAD_INCOMPLETED,

  // payment statuses

  // PAYMENT_INTEND,

  UPLOAD_ADDRESS_INFORMATION,
  PAYMENT_INTEND,

  PAYMENT_ACCEPTED,
  PAYMENT_FAILD,

  // upload address
  PAID,
  // order confirmation
  CONFIRMED_ORDER,

  PREPARING,

  DELIVERED,
}

export const STATUS_MAP = {
  [OrderStatusType.CREATED]: "Created",
  [OrderStatusType.PHOTOS_UPLOAD_COMPLETED]: "Photos Uploaded",
  [OrderStatusType.UPLOAD_ADDRESS_INFORMATION]: "Address Added",
  [OrderStatusType.PAYMENT_INTEND]: "Payment intent created",
  [OrderStatusType.PAYMENT_ACCEPTED]: "Payment accepted",
  [OrderStatusType.PAYMENT_FAILD]: "Payment failed",
  [OrderStatusType.PAID]: "Confirmed",
  [OrderStatusType.PREPARING]: "Preparing",
  [OrderStatusType.DELIVERED]: "Delivered",
  [OrderStatusType.CONFIRMED_ORDER]: "Order Confirmed",
};

export const STATUS_CLASSES_MAP = {
  [OrderStatusType.CREATED]: "bg-bluegray-100 text-bluegray-900",
  [OrderStatusType.PHOTOS_UPLOAD_COMPLETED]: "bg-teal-100 text-teal-900",
  [OrderStatusType.UPLOAD_ADDRESS_INFORMATION]: "bg-cyan-100 text-cyan-900",
  [OrderStatusType.PAYMENT_INTEND]: "bg-blue-100 text-blue-900",
  [OrderStatusType.PAYMENT_ACCEPTED]: "bg-purple-100 text-purple-900",
  [OrderStatusType.PAYMENT_FAILD]: "bg-red-100 text-red-900",
  [OrderStatusType.PAID]: "bg-purple-100 text-purple-900",
  [OrderStatusType.PREPARING]: "bg-primary-100 text-primary-900",
  [OrderStatusType.DELIVERED]: "bg-green-100 text-green-900",
  [OrderStatusType.CONFIRMED_ORDER]: "bg-purple-100 text-purple-900",
};
