import { AuthContextType } from "./AuthTypes";
import { createContext } from "react";

export const AuthContext = createContext<AuthContextType>({
  user: null,
  userAdmin: null,
  signOut: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithApple: () => Promise.resolve(),
  loading: false,
  isLoadingMethod: () => false,
  formatMessage: () => ""
});
