import { Button } from "primereact/button";
import { useAuth } from "context/auth/AuthHooks";

export const Login = () => {
  const { signInWithFacebook, signInWithGoogle, signInWithApple, loading, isLoadingMethod } = useAuth();
  return (
    <div className="flex w-full h-full align-items-center grid grid-nogutter">
      <div className="flex justify-content-center col-12 md:col-4 md:col-offset-1 lg:col-2 lg:col-offset-2">
        <img src="/assets/images/logo.svg" className="w-full max-w-10rem" alt="Logo" />
      </div>

      <div className="grid grid-nogutter col-12 md:col-5 md:col-offset-1 lg:col-3 lg:col-offset-2">
        <div className="col-12 p-2 ">
          <Button
            label="Log in with Facebook"
            className="p-button w-full  p-button-secondary"
            icon="pi pi-facebook"
            iconPos="right"
            onClick={signInWithFacebook}
            disabled
            loading={isLoadingMethod("facebook")}
          />
        </div>
        <div className="col-12 p-2">
          <Button
            label="Log in with Google"
            onClick={signInWithGoogle}
            className="p-button w-full p-button-secondary"
            icon="pi pi-google"
            iconPos="right"
            disabled={loading}
            loading={isLoadingMethod("google")}
          />
        </div>
        <div className="col-12 p-2">
          <Button
            label="Log in with Apple"
            className="p-button w-full  p-button-secondary"
            icon="pi pi-apple"
            iconPos="right"
            onClick={signInWithApple}
            disabled
            loading={isLoadingMethod("apple")}
          />
        </div>
      </div>
    </div>
  );
};
