import { GoogleAuthProvider, User, UserInfo, getAuth, signInWithPopup } from "firebase/auth";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";

import { AuthContext } from "./AuthContext";
import { FIREBASE_CONFIG } from "../../configs";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { initializeApp } from "firebase/app";
import _ from "lodash";

const googleAuthProvider = new GoogleAuthProvider();

initializeApp(FIREBASE_CONFIG);

export const db = getFirestore();

const auth = getAuth();

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const toast = useRef<Toast>(null);
  const [user, setUser] = useState<UserInfo | null>(auth.currentUser);
  const [userAdmin, setUserAdmin] = useState<any>(null);
  const [loadingField, setLoadingField] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [intl, setIntl] = useState<any>({});

  useEffect(() => {
    const docRef = doc(db, "translations", `ro`);
    getDoc(docRef)
      .then((response) => {
        if (response.exists()) {
          setIntl({ id: response.id, ...response.data() });
        }
      })
      .catch((error) => {
      });
  }, [])

  const formatMessage = (id: string) => {
    return _.get(intl, id, id)
  }

  const handleSignIn = async (promise: Promise<unknown>, method: string) => {
    setLoadingField(method);
    setLoading(true);
    try {
      await promise;
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.current?.show({
        severity: "error",
        summary: "Failed to log in",
        detail: "Make sure you have the rights to access the page",
      });
    }
  };

  const signInWithFacebook = async () => { };
  const signInWithGoogle = async () => {
    handleSignIn(signInWithPopup(auth, googleAuthProvider), "google");
  };
  const signInWithApple = async () => { };
  const isLoadingMethod = (method: string) => {
    return loading && method === loadingField;
  };

  const signOut = () => {
    return auth.signOut();
  };

  const verifyAdminUser = async (e: User) => {
    try {
      const { uid } = e;
      const result = await getDoc(doc(db, "admins", uid));

      const userAdmin = result.data();
      const exists = result.exists();
      if (exists) {
        setUserAdmin(userAdmin);
        onSnapshot(doc(db, "admins", uid), (e) => {
          setUserAdmin(e.data());
        });
      }
      return exists;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    auth.onAuthStateChanged(async (e) => {
      if (e) {
        const result = await verifyAdminUser(e);
        if (!result) {
          toast.current?.show({
            severity: "error",
            summary: "Failed to log in",
            detail: "Only admins are allowed to access the page. If you think this is a mistake please talk to the development team.",
          });
          signOut();
          return;
        }
      }
      if (!e) {
        setUserAdmin(null);
      }
      setUser(e);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <ProgressSpinner className="my-auto" />;
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          userAdmin,
          signOut,
          signInWithFacebook,
          signInWithGoogle,
          signInWithApple,
          loading,
          isLoadingMethod,
          formatMessage
        }}
      >
        {children}
      </AuthContext.Provider>
      <Toast ref={toast} />
    </>
  );
}
