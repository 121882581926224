import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { useEffect, useMemo, useRef } from "react";

export const steps = [
  {
    id: "download",
    name: "Downloading project data",
    details:
      "This is the first step where we retrieve all the information about the project.<br/><b>This is usually instant.</b>",
  },
  {
    id: "loading",
    name: "Retrieving photos",
    details:
      "In order to create the product export we need to download all the photos first. <br/><b>This may take a while depending on your internet speed.</b>",
  },
  {
    id: "parsing",
    name: "Converting photos",
    details:
      "Some photos are uploaded in a high efficiency format (HEIC). These files are not recognized by your browser so we need to convert them in JPEG first. <br />TIP: If this process fails, when starting again it takes less time. <br/><b>This may take a while depending on the photo sizes.<b>",
  },
  {
    id: "creating",
    name: "Generating your document",
    details:
      "Putting everything together and generating your assets. <br/><b>This usually doesn't take long, but it may take a while for albums with lots of photos inside. </b>",
  },
  {
    disabled: true,
    id: "zipping",
    name: "Zipping your photos",
    details:
      "We're putting everything in an archive<br/><b>This usually doesn't take long, but it may take a while for albums with lots of photos inside. </b>",
  },
  {
    id: "downloading",
    name: "Downloading your files",
    details: "Your download should begin in a few seconds... ",
  },
  {
    id: "cleaning",
    name: "Cleaning the leftovers",
    details:
      "Cleaning all the photos we temporarly saved to your computer. This should save yourself some space 🎉",
  },
];

export const OrderExporting = ({ data = {} }: any) => {
  const time = useRef(new Date().getTime());
  const currentTime = new Date().getTime() - time.current;

  const minutes = Math.floor(currentTime / 1000 / 60);
  const seconds = Math.floor(currentTime / 1000 - minutes * 60);

  const isDone = steps.every(
    (step) =>
      data[step.id] &&
      (data[step.id].disabled === true ||
        data[step.id].current / data[step.id].total > 0.99)
  );

  const renderList = (entry: any) => {
    const stepData = data[entry.id];
    const percentage = stepData ? (stepData.current / stepData.total) * 100 : 0;
    const isDone = stepData && percentage > 99.8;
    const isHidden = !stepData || isDone;

    if (stepData?.disabled) {
      return;
    }

    return (
      <li
        className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
        key={entry.id}
      >
        <div className="flex align-items-start mr-0 lg:mr-5">
          <div className="text-left">
            <span className="text-900 font-medium block mb-2">
              {entry.name}
            </span>
            {!isHidden && (
              <div>
                <div
                  className="text-600 text-sm mb-2"
                  dangerouslySetInnerHTML={{ __html: entry.details }}
                ></div>
                <ProgressBar
                  value={Math.round(percentage)}
                  color="#3490dc"
                  style={{ height: "6px" }}
                ></ProgressBar>
              </div>
            )}
          </div>
        </div>
        <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">
          {!isDone && `${Math.round(percentage)}%`}
          {isDone && (
            <Avatar
              icon="pi pi-check"
              size="normal"
              style={{ backgroundColor: "#34b233", color: "#ffffff" }}
              shape="circle"
            />
          )}
        </span>
      </li>
    );
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="text-center text-xl">
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      <ul className="list-none p-0 m-0">{steps.map(renderList)}</ul>
      {isDone && (
        <div className="text-center font-medium text-xl mt-6">
          Hooooray! Your export has finished! 🎉
          <Button
            onClick={handleRefresh}
            style={{
              backgroundColor: "#3490dc",
              border: "1px solid transparent",
              color: "#ffffff",
            }}
            className="mt-4"
          >
            Back to the project details
          </Button>
          <div className="text-sm text-500 font-regular mt-1">
            Or you can just refresh the page
          </div>
        </div>
      )}
    </>
  );
};
