import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useMemo, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TreeTable } from "primereact/treetable";

export const EditTranslations = ({ data }: any) => {
  const { watch, setValue, reset, getValues } = useFormContext();

  const [visible, setVisible] = useState(false);
  const [displayBasic, setDisplayBasic] = useState<any>(false);
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const editingId = useRef("");

  const values = watch();

  useEffect(() => {
    if (Object.keys(values).length) {
      return;
    }
    reset(data.translations);
  }, [data.translations, values, reset]);

  const languages = Object.values(values).map((v) => v.id);

  const groups = useMemo(
    () =>
      Object.values(values).reduce((previous: any, current: any) => {
        return { ...previous, ...current };
      }, {}),
    [values]
  );

  const labels = Object.values(values).map((val) => val.label);

  const root = useMemo(() => {
    return Object.keys(groups).reduce((previous, key, index) => {
      if (!previous[index]) {
        previous[index] = {};
      }
      Object.values(values).forEach((intlEntry) => {
        if (!previous[index].data) {
          previous[index].data = { name: key };
          previous[index].key = key;
        }
        if (typeof groups[key] === "string") {
          previous[index].data[intlEntry.id] = intlEntry[key] || "";
          return;
        }

        if (!previous[index].children) {
          previous[index].children = [];
        }

        Object.entries(intlEntry[key] || {}).forEach((entry, innerIndex) => {
          if (!previous[index].children[innerIndex]) {
            previous[index].children[innerIndex] = {};
            previous[index].children[innerIndex].data = { name: entry[0], parent: key };
            previous[index].children[innerIndex].key = entry[0];
          }
          previous[index].children[innerIndex].data[intlEntry.id] = intlEntry[key][entry[0]];
        });
      });
      return previous;
    }, [] as any[]);
  }, [groups, values]);

  const addIntl = (name: string) => {
    if (!editingId.current) {
      languages.forEach((_, index) => setValue(`[${index}].${name}`, checked ? {} : ""));
    } else {
      languages.forEach((_, index) => setValue(`[${index}].${editingId.current}.${name}`, checked ? {} : ""));
    }
    setName("");
  };

  const handleAddIntl = (name: string) => {
    editingId.current = name;
    setVisible(true);
  };

  const formatTemplate = (node: any) => {
    return <Chip label={node.data.name} className={node.children ? "bg-purple-400 text-white" : ""} />;
  };

  const remove = (node: any) => {
    languages.forEach((_, index) => {
      const key = node.data.parent ? `[${index}].${node.data.parent}` : `[${index}]`;
      const values = getValues(key);
      delete values[node.data.name];
      setValue(key, { ...values });
    });
    return;
  };

  const actionTemplate = (node: any, column: any) => {
    if (node.data.name === "id" || node.data.name === "label") {
      return null;
    }
    if (!node.children) {
      return (
        <div className="mr-0">
          <Button type="button" icon="pi pi-trash" className="p-button-danger " onClick={() => remove(node)}></Button>
        </div>
      );
    }
    return (
      <div>
        <Button type="button" icon="pi pi-plus" className="p-button-info " onClick={() => handleAddIntl(node.data.name)}></Button>
        <Button type="button" icon="pi pi-trash" className="p-button-danger ml-2" onClick={() => remove(node)}></Button>
      </div>
    );
  };

  return (
    <div className="grid">
      <div>
        <div className="card">
          <Button type="button" onClick={() => handleAddIntl("")} icon="pi pi-plus" label="Add translation at top level" />
          <br />
          <br />
          <TreeTable value={root as any} onSelect={(e) => !e.node.children && setDisplayBasic(e.node)} selectionMode={"single"}>
            <Column field="name" header="Name" key="name" body={formatTemplate} expander></Column>
            {languages.map((lang, index) => (
              <Column field={lang} header={labels[index]} key={index}></Column>
            ))}
            <Column body={actionTemplate} style={{ textAlign: "right", width: "10rem" }} key={"add"} />
          </TreeTable>
        </div>
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={() => (
          <div>
            <div className="col-12">
              <InputText placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className="col-12">
              <Checkbox inputId="cb1" value="New York" onChange={(e) => setChecked(e.checked)} checked={checked}></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label ml-4">
                Create a collection of translations.
              </label>
            </div>
          </div>
        )}
        header="Name of the translation"
        accept={() => addIntl(name)}
      />

      <Dialog
        header={`Edit "${displayBasic?.data?.name}"`}
        visible={displayBasic}
        style={{ width: "50vw" }}
        onHide={() => setDisplayBasic(false)}
        dismissableMask
      >
        {displayBasic && (
          <div className="grid">
            {labels.map((l, index) => {
              return (
                <Controller
                  name={
                    displayBasic.data.parent
                      ? `[${index}].${displayBasic.data?.parent}.${displayBasic.data?.name}`
                      : `[${index}].${displayBasic.data?.name}`
                  }
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div className="field col">
                        <div className="p-label">
                          <label htmlFor={l}>{l}</label>
                          <br />
                          <InputText className="w-full" id={l} value={value} onChange={(e) => onChange(e.target.value)} />
                        </div>
                      </div>
                    );
                  }}
                />
              );
            })}
          </div>
        )}
      </Dialog>
    </div>
  );
};
