/* eslint-disable no-loop-func */
/* eslint-disable no-unreachable */

import { OrderDetail, OrderType, Page, Element } from "types";
import { getRelativePath } from "utils/getProductPath";
import { getDownloadURL, ref, getStorage } from "firebase/storage";
import heic2any from "heic2any";
import html2pdf from "html2pdf.js";
import * as zip from "@zip.js/zip.js";
import { chunk } from "lodash";
import { ComputePagesEventType, WorkerResponse } from "./types/WorkerTypes";

export class CanvasComputer {
  private _threads = navigator.hardwareConcurrency;
  private workers: Worker[] = [];
  private opts: any;

  public constructor(opts: any) {
    this.opts = opts;
    if (opts.downloadType === "pdf") {
      Progress.logProgress((oldState: any) => ({
        ...oldState,
        zipping: {
          disabled: true,
        },
      }));
    }
  }

  /** Initializes the workers */
  public init() {
    this.workers = new Array(this._threads)
      .fill(0)
      .map(
        () => new Worker(new URL("./workers/computePage.ts", import.meta.url))
      );
  }

  public async computeImages() {
    // Lets initialize the workers
    this.init();

    const images = await this._getImagesDownloadUrl();
    // Split the data evenly between all workers
    const data = this._splidDataIntoChunks(images);

    // Send the convert command to each worker
    this.workers.forEach((worker, index) => {
      worker.postMessage({
        id: this.opts.detail.id,
        offset: index * data[0].length,
        type: ComputePagesEventType.Convert,
        images: data[index],
      });
    });

    try {
      // Wait for the data to be converted
      await this._listenForWorker(ComputePagesEventType.Convert, images.length);
    } catch (e) {
      // If any error is encountered we quit all
      throw new Error("worker error");
    }
    this.destroy();

    await computeImages(
      this.opts.orderDetails,
      this.opts.detail,
      this.opts.downloadType,
      this.opts.dpi,
      this.opts.margin,
      this.opts.sideBySide
    );
  }

  /** Destroys the instance of all workers */
  public destroy() {
    this.workers.forEach((worker) => worker.terminate());
    this.workers = [];
  }

  /** Calculates how big a chunk of data should be in order to evenly distribute the workloads among all workers */
  private _getChunkSize<T>(items: T[]) {
    return Math.ceil(items.length / this.workers.length);
  }

  /** Splits a generic array into multiple chunks */
  private _splidDataIntoChunks<T>(data: T[]): T[][] {
    return chunk(data, this._getChunkSize(data));
  }

  /** Returns a list of downloadable image urls */
  private async _getImagesDownloadUrl() {
    const storage = getStorage();

    const images: string[] = this.opts.detail.photos.map(
      (_: string, index: number) =>
        getRelativePath(
          this.opts.orderDetails,
          this.opts.orderDetails.orderDetails.indexOf(this.opts.detail),
          index
        )
    );

    Progress.logProgress((oldState: any) => ({
      ...oldState,
      download: { current: 1, total: 1 },
      loading: {
        current: 0,
        total: images.length,
      },
    }));

    const downloadUrls = await Promise.all(
      images.map((image) => {
        return getDownloadURL(ref(storage, image));
      })
    );

    Progress.logProgress((oldState: any) => ({
      ...oldState,
      loading: {
        current: 1,
        total: 1,
      },
    }));

    return downloadUrls;
  }

  private _listenForWorker(
    type: ComputePagesEventType,
    count: number = this.workers.length
  ) {
    let numberOfCalls = 0;
    let resolve: (message: any) => void;
    let reject: (reason: any) => void;

    const cancelListeners = () => {
      this.workers.forEach((worker) => {
        worker.removeEventListener("message", handleWorkerMessage);
        worker.removeEventListener("error", handleWorkerError);
      });
    };

    const handleWorkerMessage = (e: MessageEvent<WorkerResponse>) => {
      if (e.data.type !== type) {
        return;
      }

      numberOfCalls++;

      Progress.logProgress((oldState: any) => ({
        ...oldState,
        parsing: { current: numberOfCalls, total: count },
      }));

      if (numberOfCalls === count) {
        resolve({});
        cancelListeners();
      }
    };

    const handleWorkerError = (e: Event) => {
      cancelListeners();
      reject(e);
    };

    return new Promise((res, rej) => {
      resolve = res;
      reject = res;
      this.workers.forEach((worker) => {
        worker.addEventListener("message", handleWorkerMessage);
        worker.addEventListener("error", handleWorkerError);
      });
    });
  }
}

let globalCache: Cache;

export const Progress: {
  logProgress: (callback: (oldState: any) => any) => any;
} = {
  logProgress: () => {},
};

export const computeImages = async (
  orderDetails: OrderType,
  detail: OrderDetail,
  downloadType: string,
  dpi: number,
  margin: number,
  sideBySide: boolean
) => {
  console.log("details", detail);
  Progress.logProgress((oldState: any) => ({
    ...oldState,
    download: { current: 0, total: 2 },
  }));

  globalCache = await caches.open(detail.id);

  Progress.logProgress((oldState: any) => ({
    ...oldState,
    download: { current: 1, total: 2 },
  }));

  // const images = detail.photos.map((_, index) =>
  //   getRelativePath(
  //     orderDetails,
  //     orderDetails.orderDetails.indexOf(detail),
  //     index
  //   )
  // );

  // const loadedImages = await getAllImages(images);

  const pageFactor = sideBySide ? 2 : 1;

  // Merging first pages

  const loadedImages = await Promise.all(
    new Array(detail.photos.length)
      .fill(0)
      .map((element, index) => globalCache.match(`${index}.jpg`))
  );

  Progress.logProgress((oldState: any) => ({
    ...oldState,
    download: { current: 2, total: 2 },
  }));

  const blobs = await Promise.all(
    loadedImages.map((response) => response?.blob())
  );

  const imageUrls = blobs.map((blob) => URL.createObjectURL(blob!));

  const imageRefs = loadImageRefs(detail.photos, imageUrls);

  const pages = formatPages(
    formatSideBySideCovers(detail.pages, sideBySide),
    dpi,
    detail,
    margin,
    sideBySide,
    imageRefs
  );

  const newElement = document.createElement("div");

  pages.forEach((page) => {
    const pageBreak = document.createElement("div");
    pageBreak.className = "html2pdf__page-break";
    newElement.append(page);
  });

  console.log(
    "SIZES",
    getDpiValue(detail.product.attributes.coverSize.width * pageFactor, dpi) +
      2 * getDpiValue(margin, dpi),
    getDpiValue(detail.product.attributes.coverSize.height, dpi) +
      2 * getDpiValue(margin, dpi)
  );

  const [finalWidth, finalHeight] = [
    getDpiValue(detail.product.attributes.coverSize.width * pageFactor, dpi) +
      2 * getDpiValue(margin, dpi),
    getDpiValue(detail.product.attributes.coverSize.height, dpi) +
      2 * getDpiValue(margin, dpi),
  ];

  const aspectRatio = finalWidth / finalHeight;
  const isPortrait = aspectRatio < 1;

  var opt = {
    margin: getDpiValue(margin, dpi),
    filename: "myfile.pdf",
    html2canvas: {
      scale: 1,
      backgroundColor: detail.backgroundColor,
      imageTimeout: 60000,
    },
    jsPDF: {
      hotfixes: ["px_scaling"],
      unit: "px",
      orientation: isPortrait ? "portrait" : "landscape",
      format: [finalWidth, finalHeight],
    },
  };

  if (downloadType === "pdf") {
    // html2pdf().from(newElement).set().save();

    await downloadPDF(pages, opt);

    Progress.logProgress((oldState: any) => ({
      ...oldState,
      downloading: { current: 1, total: 1 },
    }));
  } else {
    let zipWriter = new zip.ZipWriter(new zip.BlobWriter("application/zip"), {
      bufferedWrite: true,
    });
    try {
      let index = 0;
      for (let page of pages) {
        const img = await html2pdf().set(opt).from(page).toCanvas().outputImg();

        Progress.logProgress((oldState: any) => ({
          ...oldState,
          creating: { current: index + 1, total: pages.length },
        }));

        const blob = await fetch(img.src).then((res) => res.blob());

        (await zipWriter.add(
          `${index}.jpeg`,
          new zip.BlobReader(blob),
          {}
        )) as any;

        Progress.logProgress((oldState: any) => ({
          ...oldState,
          zipping: { current: index, total: pages.length },
        }));

        index++;
      }

      Progress.logProgress((oldState: any) => ({
        ...oldState,
        downloading: { current: 1, total: 1 },
      }));
      onDownloadButtonClick(zipWriter);
    } catch (e) {}
  }
  Progress.logProgress((oldState: any) => ({
    ...oldState,
    cleaning: { current: 0, total: pages.length },
  }));
  const keys = await globalCache.keys();

  await Promise.all(
    keys.map((key, index) => {
      Progress.logProgress((oldState: any) => ({
        ...oldState,
        cleaning: { current: index, total: keys.length },
      }));
      return globalCache.delete(key);
    })
  );

  Progress.logProgress((oldState: any) => ({
    ...oldState,
    cleaning: { current: 1, total: 1 },
  }));

  document.body.append(newElement);
};

const formatSideBySideCovers = (pages: Page[], sideBySide: boolean) => {
  if (!sideBySide) {
    return pages;
  }
  const first = pages[0];
  const last = pages[pages.length - 1];
  const rest = pages.slice(1, pages.length - 1);

  const coversPage = {
    ...first,
    size: {
      ...first.size,
      width: first.size.width * 2,
    },
    elements: [
      ...last.elements.map((element) => ({
        ...element,
        width: element.width / 2,
      })),
      ...first.elements.map((element) => ({
        ...element,
        x: element.x / 2 + 0.5,
        width: element.width / 2,
      })),
    ],
  };

  return [coversPage, ...rest];
};
const loadImageRefs = (refs: string[], images: string[]) => {
  const map: Record<string, string> = {};
  refs.forEach((ref, index) => {
    map[ref] = images[index];
  });
  return map;
};

// const getAllImages = async (urls: string[]) => {
//   const downloadUrls = await getDownloadableUrl(urls);
//   Progress.logProgress((oldState: any) => ({
//     ...oldState,
//     download: { current: 2, total: 2 },
//     loading: { current: 0, total: urls.length },
//   }));
//   const blobs = await downloadBlobs(downloadUrls);

//   return normalizeImages(blobs);
// };

// const getDownloadableUrl = (urls: string[]) => {
//   return Promise.all(urls.map((url) => getDownloadURL(ref(getStorage(), url))));
// };

// const downloadBlobs = (urls: string[]) => {
//   return Promise.all(
//     urls.map(async (url) => {
//       const res = await fetch(url).then((res) => res.blob());
//       Progress.logProgress((oldState: any) => ({
//         ...oldState,
//         loading: { current: oldState.loading.current + 1, total: urls.length },
//       }));

//       return res;
//     })
//   );
// };

// const normalizeImages = async (blobs: Blob[]) => {
//   let image: any = null;

//   for (let [index, blob] of blobs.entries()) {
//     Progress.logProgress((oldState: any) => ({
//       ...oldState,
//       parsing: { current: index + 1, total: blobs.length },
//     }));
//     if (
//       await globalCache.match(
//         new URL(index.toString() + ".jpeg", window.location.origin)
//       )
//     ) {
//       continue;
//     }
//     if (blob.type === "image/heic") {
//       image = (await heic2any({
//         blob,
//         toType: "image/jpeg",
//         quality: 0.8,
//       })) as Blob;

//       await globalCache.put(
//         new URL(index.toString() + ".jpeg", window.location.origin),
//         new Response(image)
//       );
//     } else {
//       await globalCache.put(
//         new URL(index.toString() + ".jpeg", window.location.origin),
//         new Response(blob)
//       );
//     }
//   }
//   // Progress.logProgress({
//   //   step: "Comverting images",
//   //   percentage: 0.5,
//   // });

//   return Promise.all(
//     blobs.map((_, index) =>
//       globalCache
//         .match(new URL(index.toString() + ".jpeg", window.location.origin))
//         .then((e) => e?.blob())
//         .then((e) => getBase64(e!))
//     )
//   );

//   // return globalCache.matchAll(blobs.map((_, i) => new URL(i))

//   // return new Array(blobs.length).fill(1).map((_, index) => {
//   //   globalCache.match(image)

//   //  const cachedImage =  localStorage.getItem(`${index}`)
//   //   localStorage.removeItem(`${index}`)
//   //  return cachedImage!
//   // });
// };

// const getBase64 = (blob: Blob) => {
//   return new Promise<string>((res, rej) => {
//     var reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onloadend = function () {
//       res(reader.result as string);
//     };
//     reader.onerror = (e) => {
//       rej(e);
//     };
//   });
// };

//Formatters
const getDpiValue = (totalinCm: number, dpi: number, fraction?: number) => {
  const INCH_CM = 2.54;
  const dpc = dpi / INCH_CM;

  const total = dpc * totalinCm;

  if (!fraction) {
    return Math.ceil(total);
  }

  return total * fraction;
};
const px = (value: number) => {
  return `${value}px`;
};
const precentage = (value: number, pxOffset?: number) => {
  if (pxOffset) {
    return `calc(${value * 100}% + ${pxOffset}px)`;
  }
  return `${value * 100}%`;
};

// Format pages divs
const formatPages = (
  pages: Page[],
  dpi: number,
  detail: OrderDetail,
  margin: number,
  sideBySide: boolean,
  imageRefs: any
) => {
  return pages.flatMap((page): HTMLDivElement[] => {
    if (
      detail.product.projectConfig.showMiddleSepparator &&
      page.size.width !== detail.product.attributes.coverSize.width &&
      !sideBySide
    ) {
      const firstPage: Page = {
        ...page,
        size: {
          ...page.size,
          width: page.size.width / 2,
        },
        elements: page.elements
          .filter((element) => element.subPage === 1)
          .map((element) => ({
            ...element,
            x: element.x * 2,
            width: element.width * 2,
          })),
      };

      const secondPage: Page = {
        ...page,
        size: {
          ...page.size,
          width: page.size.width / 2,
        },
        elements: page.elements
          .filter((element) => element.subPage === 2)
          .map((element) => ({
            ...element,
            x: element.x * 2 - 1,
            width: element.width * 2,
          })),
      };

      return formatPages(
        [firstPage, secondPage],
        dpi,
        detail,
        margin,
        sideBySide,
        imageRefs
      );
    }

    const outerElement = document.createElement("div");
    const element = document.createElement("div");

    // element.style.padding = px(getDpiValue(margin, dpi))

    // Outer Element sizing

    // In this case the html will be rotated to match the base reference of the page
    let shouldRotate = false;
    if (
      detail.product.attributes.coverSize.width !== page.size.width &&
      !detail.product.projectConfig.showMiddleSepparator &&
      !sideBySide
    ) {
      outerElement.style.width = px(getDpiValue(page.size.height, dpi));
      outerElement.style.height = px(getDpiValue(page.size.width, dpi));
      shouldRotate = !sideBySide;
      element.style.transformOrigin = "center";
    } else {
      outerElement.style.width = px(getDpiValue(page.size.width, dpi));
      outerElement.style.height = px(getDpiValue(page.size.height, dpi));
    }
    outerElement.style.position = "relative";

    element.style.width = px(getDpiValue(page.size.width, dpi));
    element.style.height = px(getDpiValue(page.size.height, dpi));

    element.style.backgroundColor = detail.backgroundColor;
    element.style.position = "absolute";
    element.style.top = "50%";
    element.style.left = "50%";
    element.style.transform = `translateY(-50%) translateX(-50%) ${
      shouldRotate ? "rotate(90deg)" : ""
    }`;

    outerElement.append(element);

    element.append(
      ...formatPageElements(
        page.elements,
        dpi,
        detail,
        {
          width: getDpiValue(detail.product.attributes.coverSize.width, dpi),
          height: getDpiValue(detail.product.attributes.coverSize.height, dpi),
        },
        imageRefs
      )
    );

    return [outerElement];
  });
};

const formatPageElements = (
  elements: Element[],
  dpi: number,
  detail: OrderDetail,
  { width, height }: { width: number; height: number },
  imageRefs: any
) => {
  return elements.map((element) => {
    const htmlElement = document.createElement("div");
    htmlElement.style.top = precentage(element.y);
    htmlElement.style.left = precentage(element.x);
    htmlElement.style.width = precentage(element.width);
    htmlElement.style.height = precentage(element.height);
    htmlElement.style.position = "absolute";
    htmlElement.style.overflow = "hidden";

    htmlElement.append(
      formatElementInner(element, dpi, detail, { width, height }, imageRefs)
    );

    return htmlElement;
  });
};

const formatElementInner = (
  element: Element,
  dpi: number,
  detail: OrderDetail,
  { width, height }: { width: number; height: number },
  imageRefs: any
) => {
  if (element.type === "Image") {
    const image = document.createElement("div");

    image.style.transform = `translate(${px(element.innerX * width)}, ${px(
      element.innerY * height
    )}) scale(${element.scale})`;
    image.style.width = "100%";
    image.style.height = "100%";
    image.style.position = "absolute";
    image.style.backgroundSize = element.resizeMode;
    image.style.backgroundRepeat = "no-repeat";
    image.style.backgroundPosition = "center";
    image.style.backgroundImage = `url("${imageRefs[element.imageUrl]}")`;

    return image;
  }

  const text = document.createElement("div");

  if (!element.scale) {
    return text;
  }

  const fontSize = element.size * width;

  text.innerHTML = element.text.replaceAll(" ", "&nbsp;");
  text.style.fontFamily = element.fontFamily;
  text.style.fontWeight = element.fontWeight;
  text.style.maxWidth = precentage(1 - element.innerX - element.size);
  text.style.maxHeight = "100%";
  text.style.fontSize = px(fontSize);
  text.style.color = element.color;
  text.style.display = "inline-block";
  text.style.position = "absolute";
  text.style.maxHeight = "100%";
  text.style.letterSpacing = "0";
  text.style.lineHeight = px(fontSize * 0.98);
  text.style.fontOpticalSizing = "none";
  text.style.backgroundColor = element.withBackground
    ? detail.backgroundColor
    : "transparent";
  text.style.padding = `${px(fontSize / 4)} ${px(fontSize / 2)}`;
  text.style.transform = `translateX(${px(
    element.innerX * width
  )}) translateY(${px(element.innerY * height)})`;
  // TO-DO: Handle Text
  return text;
};

const downloadPDF = (elements: HTMLElement[], options: any) => {
  console.log("settings", options);
  let worker = html2pdf().set(options).from(elements[0]);

  if (elements.length > 1) {
    worker = worker.toPdf(); // worker is now a jsPDF instance

    // add each element/page individually to the PDF render process
    elements.slice(1).forEach((element, index, arr) => {
      worker = worker
        .get("pdf")
        .then((pdf: any) => {
          Progress.logProgress((oldState: any) => ({
            ...oldState,
            creating: { current: index + 2, total: elements.length },
          }));
          // Progress.logProgress({
          //   step: "Creating your pdf",
          //   percentage: 0.5 + (0.5 / arr.length) * index,
          // });
          pdf.addPage();
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf();
    });
  }

  return worker.save();
};

async function onDownloadButtonClick(model: any) {
  let blobURL;
  try {
    blobURL = await URL.createObjectURL(await model.close());
  } catch (error) {
    alert(error);
  }
  if (blobURL) {
    const anchor = document.createElement("a");
    const clickEvent = new MouseEvent("click");
    anchor.href = blobURL;
    anchor.download = "Export.zip";
    anchor.dispatchEvent(clickEvent);
  }
}
