import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { FormProvider, useForm } from "react-hook-form";
import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { db } from "context";

export const CmsForm = ({ title, data, dataKey = "id", emptyState, children, collectionName, isCollection }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);

  const selectedData = id === "new" ? emptyState : data.find((dataPiece: any) => dataPiece.id === id) || emptyState;

  const methods = useForm({ defaultValues: selectedData });

  useEffect(() => {
    methods.reset(selectedData);
  }, [selectedData, methods]);

  const onSubmit = async (data: any) => {
    if (isCollection) {
      try {
        await Promise.all(data.map(({ id, ...data }: any) => setDoc(doc(db, collectionName, id), data)));
        toast.current?.show({ severity: "success", summary: "Collection", detail: <>Collection {title} updated successfully</> });
      } catch (e) {
        toast.current?.show({
          severity: "error",
          summary: title,
          detail: <>Error while updating the collection</>,
        });
      }
      return;
    }
    if (!data.id) {
      const colRef = collection(db, collectionName);
      try {
        const id = await addDoc(colRef, data);
        toast.current?.show({
          severity: "success",
          summary: title,
          detail: (
            <>
              A new document was added with the id <b>{id.id}</b>
            </>
          ),
        });

        navigate("../", { relative: "path" });
      } catch (e) {
        toast.current?.show({
          severity: "error",
          summary: title,
          detail: <>Error while adding the document</>,
        });
      }
    }
    const colRef = doc(db, collectionName, data.id);
    try {
      await setDoc(colRef, data);
      toast.current?.show({
        severity: "success",
        summary: selectedData[dataKey],
        detail: <>The document was successfully updated</>,
      });
      navigate("../", { relative: "path" });
    } catch (e) {
      toast.current?.show({
        severity: "error",
        summary: selectedData[dataKey],
        detail: <>Error while updating the document</>,
      });
    }
  };

  if (!selectedData) {
    return <h3>Data not found</h3>;
  }

  const removeItem = async () => {
    const colRef = doc(db, collectionName, id as any);
    try {
      await deleteDoc(colRef);
      navigate("../", { relative: "path" });
    } catch (e) {
    }
  };

  const confirmDelete = () => {
    confirmDialog({
      message: `Are you sure you want to delete ${title}: ${selectedData[dataKey]}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => removeItem(),
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center p-3 sticky top-0">
        <h2 className="m-0">
          {title}: {id === "new" ? "New" : selectedData[dataKey]}
        </h2>
        <div>
          <Button icon="pi pi-check" label="Save" onClick={methods.handleSubmit(onSubmit)} />
          {id !== "new" && !isCollection && <Button icon="pi pi-trash" className="ml-2" onClick={confirmDelete} />}
        </div>
      </div>
    );
  };

  return (
    <Card className=" mb-4 p-card-no-shadow p-0 no-padding" header={renderHeader}>
      <div className="p-3">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
      </div>

      <ConfirmDialog />

      <Toast ref={toast} position={"bottom-center"} />
    </Card>
  );
};
