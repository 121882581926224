import { Navigate, Route, Routes } from "react-router-dom";
import { RoleGuard, useRoleGuard } from "context/auth/RoleGuard";

import { AuthGuard } from "./context/auth/AuthGuard";
import { AuthProvider } from "context/auth/AuthProvider";
import { Cms } from "pages/CMS/Cms";
import { Menubar } from "./common";
import { OrderDetails } from "pages/OrderDetails/OrderDetails";
import { Orders } from "pages/Orders/Orders";

export const App = () => {
  return (
    <AuthProvider>
      <AuthGuard>
        <Menubar />
        <div className="p-4 flex flex-column flex-grow-1">
          <Routes>
            <Route
              path="/orders"
              element={
                <RoleGuard role="orders">
                  <Orders />
                </RoleGuard>
              }
            />
            <Route
              path="/orders/:id"
              element={
                <RoleGuard role="orders">
                  <OrderDetails />
                </RoleGuard>
              }
            />
            <Route
              path="/cms"
              element={
                <RoleGuard role="cms">
                  <Cms />
                </RoleGuard>
              }
            />
            <Route
              path="/cms/*"
              element={
                <RoleGuard role="cms">
                  <Cms />
                </RoleGuard>
              }
            />
            <Route
              path="*"
              element={
                <RoleGuard role="orders">
                  <Navigate to="/orders" />
                </RoleGuard>
              }
            />
          </Routes>
        </div>
      </AuthGuard>
    </AuthProvider>
  );
};
