import { Controller, useFormContext } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

export const EditProductVariants = ({ data }: any) => {
  const { register } = useFormContext();

  const products = data["products"] || [];

  return (
    <div className="grid">
      <div className="col-8 field">
        <label htmlFor="name-input" className="block text-sm pl-2 ">
          Name
        </label>
        <InputText className="w-full" id="name-input" {...register("name")} />
      </div>
      <div className="col-8 field">
        <label htmlFor="name-input" className="block text-sm pl-2 ">
          Attribute
        </label>
        <InputText className="w-full" id="name-input" {...register("attribute")} />
        <small className="block">The attribute name that will appear on product attributes when selected</small>
      </div>
      <div className="col-8 field">
        <label htmlFor="name-input" className="block text-sm pl-2 ">
          Attribute value
        </label>
        <InputText className="w-full" id="name-input" {...register("value")} />
        <small className="block">The attribute value for the selected attribute name</small>
      </div>
      <div className="col-8 field">
        <label htmlFor="variants-input" className="block text-sm pl-2 ">
          Products
        </label>

        <Controller
          name="products"
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
            <MultiSelect
              id="products-input"
              display="chip"
              className="w-full"
              optionLabel="name"
              optionValue="id"
              options={products || []}
              onBlur={onBlur}
              value={value?.map((v: any) => v.id)}
              filter
              onChange={(e) =>
                onChange(
                  e.value
                    ?.map((id: any) => ({ id }))
                    .sort(
                      ({ id: id1 }: any, { id: id2 }: any) =>
                        (products.find((p: any) => p.id === id1)?.order || 0) - (products.find((p: any) => p.id === id2)?.order || 0)
                    )
                )
              }
            />
          )}
        />
      </div>
    </div>
  );
};
