import { Card } from "primereact/card";

export function DeletionSteps() {
  return (
    <div className="flex w-full h-full p-4" style={{ placeContent: "center" }}>
      <div
        className="flex col "
        style={{
          width: 500,
          maxWidth: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/images/logo.svg"
          className="w-full max-w-10rem my-6"
          alt="Logo"
        />

        <Card
          title="How to delete your Account & Data?"
          subTitle={`This guide allows you to delete your Pocket Labs account along with all the data associated with your account.`}
          className="mb-4 p-card-no-shadow"
          style={{ width: 500, maxWidth: "100%" }}
        >
          <h4 className="my-0">
            Warning: This action will also delete your in progess orders!
          </h4>
          <h4 className="my-2">Warning: This action cannot be undone!</h4>
          <h3 className="mt-4 mb-0">Step 1. Navigate to "My account"</h3>
          <p className="my-1">
            The "My Account" menu is located in the bottom-right corner of the
            main menu
          </p>
          <img
            src="/step-1.jpg"
            alt="Step 1 and Step 2 of deleting accounts"
            style={{ width: "100%" }}
          />
          <h3 className="mt-4 mb-0">Step 2. Click "Delete account"</h3>
          <p className="my-1">A confirmation popup should appear</p>

          <h3 className="mt-4 mb-0">Step 3. Click "Delete account"</h3>
          <p className="my-1">
            This is the final confirmation. After you click this button all the
            data we store about you will be deleted and you will be{" "}
            <strong>logged out</strong>!
            <br />
            <br />
            <strong>
              Be aware that if you log in again using social platforms, you will
              have to fill again all the information!
            </strong>
            !
          </p>
          <img
            src="/step-2.jpg"
            alt="Step 3 of deleting accounts"
            style={{ width: "100%" }}
          />
        </Card>
      </div>
    </div>
  );
}
