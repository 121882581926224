import { Avatar } from "primereact/avatar";
import { Menubar as PrimeMenubar } from "primereact/menubar";
import { useAuth } from "context";
import { useNavigate } from "react-router-dom";
import { useRoleGuard } from "context/auth/RoleGuard";

export const Menubar = () => {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const items = [
    {
      label: "Orders",
      icon: "pi pi-fw pi-file",
      command: () => navigate("/orders"),
      visible: useRoleGuard("orders"),
    },
    {
      label: "Content Management System",
      icon: "pi pi-fw pi-pencil",
      command: () => navigate("/cms"),
      visible: useRoleGuard("cms"),
    },
    {
      label: "Log Out",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        return signOut();
      },
    },
  ];

  const start = (
    <img alt="logo" src="/assets/images/logo-dark.svg" height="40" onClick={() => navigate("/")} className="mr-4 ml-2 cursor-pointer"></img>
  );

  // TODO get user initials form firebase

  const capitalizedName = user?.displayName
    ?.split?.(" ")
    ?.map((capital) => capital[0].toUpperCase())
    ?.join("");

  const end = (
    <>
      <Avatar icon={user?.photoURL && <img src={user?.photoURL} />} />
    </>
  );

  return <PrimeMenubar className="border-noround" model={items} start={start} end={end} />;
};
