import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";

export const CmsTable = ({ data, title, cols }: any) => {
  const navigate = useNavigate();
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">{title}</h2>

        <div>
          <Button icon="pi pi-plus" label="New" onClick={() => navigate("new", { relative: "path" })} />
        </div>
      </div>
    );
  };
  return (
    <Card className=" mb-4 p-card-no-shadow p-0 no-padding">
      <DataTable
        value={data}
        title="Variants"
        header={renderHeader}
        rowHover
        onRowClick={(row) => navigate(row.data.id, { relative: "path" })}
      >
        {cols.map((col: any) => (
          <Column {...col} key={col.field}></Column>
        ))}
      </DataTable>
    </Card>
  );
};
