import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

const Attribute = ({ label, name, initialState, children }: any) => {
  return (
    <Controller
      name={`attributes.${name}`}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
        <div className="pb-3">
          <div className="col-12 pl-2">
            <Checkbox
              inputId={`input-${name}`}
              onChange={(e) => onChange(e.checked ? initialState : undefined)}
              checked={value !== undefined}
            />
            <label htmlFor={`input-${name}`} className="p-checkbox-label ml-2">
              {label}
            </label>
          </div>
          <div className="pl-2">{children}</div>
        </div>
      )}
    />
  );
};

export const EditProduct = ({ data }: any) => {
  const { register, watch } = useFormContext();

  const projectConfigs = data["project-configs"] || [];

  const values = watch();

  return (
    <div className="grid">
      <div className="col-6 grid">
        <div className="col-12 field">
          <label htmlFor="name-input" className="block text-sm pl-2 ">
            Name
          </label>
          <InputText className="w-full" id="name-input" {...register("name")} />
        </div>

        <div className="col-12 field">
          <label htmlFor="description-input" className="block text-sm pl-2 ">
            Description
          </label>
          <InputTextarea autoResize className="w-full" id="description-input" {...register("description")} />
        </div>

        <div className="col-12 field">
          <label htmlFor="products-input" className="block text-sm pl-2 ">
            Project Config
          </label>
          <Controller
            name="projectConfig"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
              <Dropdown
                id="products-input"
                className="w-full"
                optionLabel="name"
                optionValue="id"
                options={projectConfigs || []}
                onBlur={onBlur}
                value={value?.id}
                filter
                onChange={(e) => onChange({ id: e.value })}
              />
            )}
          />
        </div>

        <div className="col-12 field">
          <label className="block text-sm pl-2 ">Attributes</label>
          <Attribute label="Price" name="price" initialState={{ value: 0, currency: "RON" }}>
            {values.attributes.price && (
              <div className="grid">
                <div className="col-6 field">
                  <label htmlFor="price-value-input" className="block text-sm pl-2 ">
                    Price value
                  </label>
                  <InputText
                    className="w-full"
                    id="price-value-input"
                    {...register("attributes.price.value", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-6 field">
                  <label htmlFor="price-value-input" className="block text-sm pl-2 ">
                    Price currency
                  </label>
                  <InputText className="w-full" id="price-value-input" {...register("attributes.price.currency")} />
                </div>
              </div>
            )}
          </Attribute>

          <Attribute label="Price per extra page" name="pricePerExtraPage" initialState={{ value: 0, currency: "RON" }}>
            {values.attributes.pricePerExtraPage && (
              <div className="grid">
                <div className="col-6 field">
                  <label htmlFor="pricePerExtraPage-value-input" className="block text-sm pl-2 ">
                    Price Per Extra Page value
                  </label>
                  <InputText
                    className="w-full"
                    id="pricePerExtraPage-value-input"
                    {...register("attributes.pricePerExtraPage.value", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-6 field">
                  <label htmlFor="pricePerExtraPage-value-input" className="block text-sm pl-2 ">
                    Price Per Extra Page currency
                  </label>
                  <InputText className="w-full" id="pricePerExtraPage-value-input" {...register("attributes.pricePerExtraPage.currency")} />
                </div>
              </div>
            )}
          </Attribute>

          <Attribute label="Pages" name="pages" initialState={0}>
            {values.attributes.pages !== undefined && (
              <div className="grid">
                <div className="col-6 field">
                  <label htmlFor="pages-value-input" className="block text-sm pl-2 ">
                    Number of pages
                  </label>
                  <InputText
                    className="w-full"
                    id="pages-value-input"
                    {...register("attributes.pages", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
              </div>
            )}
          </Attribute>

          <Attribute label="Max Number of pages" name="maxPages" initialState={0}>
            {values.attributes.maxPages !== undefined && (
              <div className="grid">
                <div className="col-6 field">
                  <label htmlFor="maxPages-value-input" className="block text-sm pl-2 ">
                    Max Number of pages
                  </label>
                  <InputText
                    className="w-full"
                    id="maxPages-value-input"
                    {...register("attributes.maxPages", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
              </div>
            )}
          </Attribute>

          <Attribute label="Size" name="size" initialState={{ width: 0, height: 0, unit: "CM" }}>
            {values.attributes.size && (
              <div className="grid">
                <div className="col-6 field mb-0">
                  <label htmlFor="size.width-value-input" className="block text-sm pl-2 ">
                    Width
                  </label>
                  <InputText
                    className="w-full"
                    id="size.width-value-input"
                    {...register("attributes.size.width", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-6 field mb-0">
                  <label htmlFor="size.height-value-input" className="block text-sm pl-2 ">
                    Height
                  </label>
                  <InputText
                    className="w-full"
                    id="size.height-value-input"
                    {...register("attributes.size.height", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-12 field mb-0">
                  <label htmlFor="size.width-value-input" className="block text-sm pl-2 ">
                    Unit
                  </label>
                  <Controller
                    name="attributes.size.unit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Dropdown
                        inputId="size.width-value-input"
                        className="w-full"
                        options={["CM", "INCHES"]}
                        onBlur={onBlur}
                        value={value}
                        filter
                        onChange={(e) => onChange(e.value)}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </Attribute>

          <Attribute label="Cover Size" name="coverSize" initialState={{ width: 0, height: 0, unit: "CM" }}>
            {values.attributes.coverSize && (
              <div className="grid">
                <div className="col-6 field mb-0">
                  <label htmlFor="coverSize.width-value-input" className="block text-sm pl-2 ">
                    Width
                  </label>
                  <InputText
                    className="w-full"
                    id="coverSize.width-value-input"
                    {...register("attributes.coverSize.width", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-6 field mb-0">
                  <label htmlFor="coverSize.height-value-input" className="block text-sm pl-2 ">
                    Height
                  </label>
                  <InputText
                    className="w-full"
                    id="coverSize.height-value-input"
                    {...register("attributes.coverSize.height", { setValueAs: (v) => Number(v) || 0 })}
                  />
                </div>
                <div className="col-12 field mb-0">
                  <label htmlFor="coverSize.width-value-input" className="block text-sm pl-2 ">
                    Unit
                  </label>
                  <Controller
                    name="attributes.coverSize.unit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Dropdown
                        inputId="coverSize.width-value-input"
                        className="w-full"
                        options={["CM", "INCHES"]}
                        onBlur={onBlur}
                        value={value}
                        filter
                        onChange={(e) => onChange(e.value)}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </Attribute>
          <Controller
            name="descriptionFields"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
              <div className="grid">
                <div className="col-12 field">
                  <label htmlFor="descriptionFields-value-input" className="block text-sm pl-2 ">
                    Description fields
                  </label>
                  <InputText
                    className="w-full"
                    id="descriptionFields-value-input"
                    value={value.join(",")}
                    onChange={(e) => onChange(e.target.value.split(",").map((e) => e.trim()))}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name="attributeIcons"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
              <div className="grid">
                <div className="col-12 field">
                  <label htmlFor="attributeIcons-value-input" className="block text-sm pl-2 ">
                    Attribute icons
                  </label>
                  <InputText
                    className="w-full"
                    id="attributeIcons-value-input"
                    value={value.join(",")}
                    onChange={(e) => onChange(e.target.value.split(",").map((e) => e.trim()))}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name="cartFields"
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
              <div className="grid">
                <div className="col-12 field">
                  <label htmlFor="cartFields-value-input" className="block text-sm pl-2 ">
                    Cart fields
                  </label>
                  <InputText
                    className="w-full"
                    id="cartFields-value-input"
                    value={value.join(",")}
                    onChange={(e) => onChange(e.target.value.split(",").map((e) => e.trim()))}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="col-6 grid items-start">
        <div className="col-12 field">
          <label htmlFor="imageUrl-input" className="block text-sm pl-2 ">
            Image URL
          </label>
          <InputText className="w-full" id="imageUrl-input" {...register("imageUrl")} />
        </div>
        {values.imageUrl && (
          <div className="col-6 field">
            <label className="block text-sm pl-2 ">Preview Image URL</label>
            <img src={values.imageUrl} className="w-full" alt="decorative" />
          </div>
        )}
      </div>
    </div>
  );
};
