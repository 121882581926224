export enum ComputePagesEventType {
  Convert,
  Construct,
}

export interface ComputePagesConvertEvent {
  id: string;
  offset: number;
  type: ComputePagesEventType.Convert;
  images: string[];
}

export interface ComputePagesConstructEvent {
  id: string;
  type: ComputePagesEventType.Construct;
}

export type ComputePagesEvent =
  | ComputePagesConvertEvent
  | ComputePagesConstructEvent;

export interface ImageInterface {
  buffer: ArrayBuffer;
  type: string;
}

export interface WorkerResponse {
  type: ComputePagesEventType;
}
