import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { FunctionComponent, useState } from "react";
import { OrderDetail, OrderType } from "types";

interface PropTypes {
  orderDetails: OrderType;
  productPath: string;
  name: string;
  details: OrderDetail;
  exporting: boolean;
  handleDownload: any;
}

export const Product: FunctionComponent<PropTypes> = ({
  orderDetails,
  productPath,
  name,
  details,
  exporting,
  handleDownload,
}) => {
  const [margin, setMargin] = useState<string>('');
  const [dpi, setDpi] = useState<string>('');
  const [sideBySide, setSideBySide] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-row">
        <p className="pr-1">Margin(cm):</p>
        <InputNumber
          defaultValue={0}
          placeholder="0"
          onChange={(e) => setMargin((e.originalEvent.target as HTMLInputElement).value)}
        />
        <p className="pl-3 pr-1">DPI:</p>
        <InputNumber
          defaultValue={300}
          placeholder="300"
          onChange={(e) => setDpi((e.originalEvent.target as HTMLInputElement).value)}
        />
        <div className="align-self-center ml-3">
          <Checkbox inputId={`input-${details.id}`} onChange={() => setSideBySide(!sideBySide)} checked={sideBySide}></Checkbox>
          <label htmlFor={`input-${details.id}`} className="p-checkbox-label ml-1">Print pages side by side (Back cover-Cover, 1-2, 3-4, 5-6, ...)</label>
        </div>
      </div>
      <div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
          <img
            src={productPath}
            alt="product"
            className="w-8rem h-8rem mr-3 flex-shrink-0"
          />
          <div className="flex flex-column my-auto text-center md:text-left">
            <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">
              {name}
            </span>
            <span className="text-600 text-sm mb-3">
              {details.product.attributes.price.value / 100} RON
            </span>
            <span className="text-600 text-sm mb-3">
              {details.product.attributes.size.width} x{" "}
              {details.product.attributes.size.height}{" "}
              {details.product.attributes.size.unit}
            </span>
            <span className="text-600 text-sm mb-3">
              {(details.product.attributes as any).coverType}
            </span>
          </div>
        </div>
        <div>

          <Button icon="pi pi-check" label="Download Printables as PDF" loading={exporting} onClick={() =>
            handleDownload(orderDetails, details, "pdf", Number(dpi) || 300, margin || 0, sideBySide)
          } />
          <Button icon="pi pi-check" label=" Download Printables as JPEG" className="ml-2" loading={exporting} onClick={() =>
            handleDownload(orderDetails, details, "jpeg", Number(dpi) || 300, margin || 0, sideBySide)
          } />
        </div>
      </div>
    </>
  );
};
